import React from "react";
import GoogleMapComponent from "./GoogleMapComponent";
import CollapsedPanel from "./collapsedPanel";
import "./styles.css";

const MapContainer = () => {
    return (
        <div className="map-wrapper">
            <header className="map-header">Raspored vozača na mapi</header>

            <div className="map-layout">
                {/* Left Sidebar */}
                <CollapsedPanel>
                    <h1>Neki tekst</h1>
                </CollapsedPanel>

                {/* Map Container */}
                <div className="map-container">
                    <GoogleMapComponent />
                </div>
            </div>
        </div>
    );
};

export default MapContainer;