import React from "react";
import "./styles.css"; // Import the CSS file

const Footer = () => {
    return (
        <footer className="footer">
            <p>© 2025 narucitaxi.com</p>
        </footer>
    );
};

export default Footer;
