import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { AuthProvider } from "./authContext";
import Header from "./header";
import LogIn from "./logIn";
import LogOut from "./logOut";
import MapContainer from "./mapContainer";
import Footer from "./footer";
import AuthenticatedRoute from "./authenticatedRoute"; // Ensures authentication protection
import "./styles.css";

const App = () => {
    return (
        <AuthProvider>
            <Router>
                <div className="app-container">
                    <Header />
                    <div className="app-body">
                        <Routes>
                            <Route path="/" element={<LogIn />} />
                            <Route path="/logout" element={<LogOut />} />
                            <Route path="/maps" element={<AuthenticatedRoute><MapContainer /></AuthenticatedRoute>} />
                        </Routes>
                    </div>
                    <Footer />
                </div>
            </Router>
        </AuthProvider>
    );
};

export default App;
