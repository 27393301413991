import axios from 'axios';
import Cookies from 'js-cookie';

// Create an Axios instance
const api = axios.create({
    baseURL: process.env.BASE_URL,
    withCredentials: true,
});

// Function to get the access token from localStorage or any other source
const getAccessToken = () => {
    return localStorage.getItem('authToken'); // or however you store it
};

// Function to set a new access token after refreshing
const setAccessToken = (token) => {
    localStorage.setItem('authToken', token); // Save new access token
};

const logout = () => {
    // Remove tokens from storage
    localStorage.removeItem("authToken");
    Cookies.remove("refreshToken");

    // Redirect to login page
    window.location.href = "/";
};

// Request interceptor: add the access token to headers
api.interceptors.request.use(
    (config) => {
        const token = getAccessToken();
        if (token) {
            config.headers['Authorization'] = `Bearer ${token}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

// Response interceptor: handle 403 error and refresh the token
api.interceptors.response.use(
    (response) => {
        return response;
    },
    async (error) => {
        const originalRequest = error.config;

        // Check if it's a 403 error and we're not already attempting to refresh the token
        if (error.response && error.response.status === 403 && !originalRequest._retry) {
            originalRequest._retry = true; // Mark this request as a retry
            try {
                // Call the refresh token endpoint to get a new access token
                console.log("Access token is expired");
                const refreshToken = Cookies.get('refreshToken');

                const refreshResponse = await axios.post('/refresh', { refreshToken });

                // If refresh is successful, update the access token and retry the original request
                const newAccessToken = refreshResponse.data.accessToken;
                setAccessToken(newAccessToken); // Update localStorage with the new access token

                console.log("New access token is :", newAccessToken);
                // Set the new token in the original request headers
                originalRequest.headers['Authorization'] = `Bearer ${newAccessToken}`;

                // Retry the original request
                return axios(originalRequest);
            } catch (refreshError) {
                // If refresh fails, log out the user or handle the error
                logout();
                // Redirect to login or logout logic here
                return Promise.reject(refreshError);
            }
        }

        return Promise.reject(error); // For other errors, just reject them
    }
);

export default api;
