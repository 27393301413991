import React, { useState, useContext, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { AuthContext } from "./authContext";
import "./styles.css";

const LogIn = () => {
    const [credentials, setCredentials] = useState({ username: "", password: "" });
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const { login, isAuthenticated } = useContext(AuthContext);
    const navigate = useNavigate();
    const usernameRef = useRef();

    // Auto-focus on username input when component mounts
    useEffect(() => {
        usernameRef.current?.focus();
    }, []);

    // Redirect if already logged in
    useEffect(() => {
        if (isAuthenticated) {
            navigate("/maps", { replace: true });
        }
    }, [isAuthenticated, navigate]);

    const handleChange = (e) => {
        setCredentials({ ...credentials, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        setError(null);

        try {
            const response = await axios.post("/supervisor/login", credentials);
            login(response.data.token);  // ✅ Update AuthContext
        } catch (error) {
            console.error("Login failed", error);
            setError("Invalid username or password");
        } finally {
            setIsLoading(false);
        }
    };

    return (       
            <form onSubmit={handleSubmit} className="form__container">
                <h2>Login</h2>
                {error && <p className="error-message">{error}</p>}
                <div className="form__controls">
                    <label htmlFor="username">Username</label>
                    <input
                        ref={usernameRef}
                        type="text"
                        id="username"
                        name="username"
                        placeholder="Username"
                        value={credentials.username}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className="form__controls">
                    <label htmlFor="password">Password</label>
                    <input
                        id="password"
                        type="password"
                        name="password"
                        placeholder="Password"
                        value={credentials.password}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className="form__controls">
                    <button type="submit" className="button" disabled={isLoading}>
                        {isLoading ? "Logging in..." : "Log In"}
                    </button>
                </div>
            </form>
    );
};

export default LogIn;
