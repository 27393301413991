import { useState } from "react";
import "./styles.css";

export default function CollapsePanel({ children }) {
    const [open, setOpen] = useState(false);

    return (
        <div className={`sidebar ${open ? "open" : "closed"}`}>
            {/* Toggle Button */}
            <button className="toggle-btn" onClick={() => setOpen(!open)}>
                {open ? "<<" : ">>"}
            </button>

            {/* Panel Content */}
            {open && <div className="content">{children}</div>}
        </div>
    );
}
