import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { AuthContext } from "./authContext";
import "./styles.css"; // Using styles.css

const LogOut = () => {
    const { logout } = useContext(AuthContext);
    const navigate = useNavigate();

    const handleLogout = () => {
        axios.post("/supervisor/logout")
            .then(() => {
                logout(); // Clears authentication state
                navigate("/"); // Redirects to login page
            })
            .catch((error) => {
                console.error("Logout failed", error);
            });
    };

    return (
        <div id="logOut-container">
            <button className="button" onClick={handleLogout}>Log Out</button>
        </div> 
    );
};

export default LogOut;
