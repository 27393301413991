import React, { useContext } from "react";
import { AuthContext } from "./authContext";
import LogOut from "./logOut";
import "./styles.css";

const Header = () => {
    const { isAuthenticated } = useContext(AuthContext);

    return (
        <header className="header">
            <h1>NaruciTaxi kontrolna tabla dispečera</h1>
            {isAuthenticated && <LogOut />}
        </header>
    );
};

export default Header;
