import React, { useState, useEffect, useRef } from 'react';
import { GoogleMap, MarkerF, useLoadScript, InfoWindow } from '@react-google-maps/api';
import axiosInstance from './api/axiosInstance';
import redIconUrl from './images/red.png';
import blueIconUrl from './images/blue.png';
import * as constants from './constants.js';

const mapsApiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
const googleMapStyle = { width: '100%', height: '100%' };
const center = { lat: 0, lng: 0 };

const neutralMapStyle = [
    { elementType: 'geometry', stylers: [{ color: '#f5f5f5' }] },
    { elementType: 'labels.icon', stylers: [{ visibility: 'off' }] },
    { elementType: 'labels.text.fill', stylers: [{ color: '#616161' }] },
    { elementType: 'labels.text.stroke', stylers: [{ color: '#f5f5f5' }] },
    { featureType: 'administrative', elementType: 'geometry', stylers: [{ visibility: 'off' }] },
    { featureType: 'poi', elementType: 'geometry', stylers: [{ color: '#eeeeee' }] },
    { featureType: 'road', elementType: 'geometry', stylers: [{ color: '#ffffff' }] },
    { featureType: 'water', elementType: 'geometry', stylers: [{ color: '#c9c9c9' }] },
    { featureType: 'road', elementType: 'labels.icon', stylers: [{ visibility: 'off' }] }
];

const redIcon = { url: redIconUrl };
const blueIcon = { url: blueIconUrl };

const GoogleMapComponent = () => {
    const { isLoaded } = useLoadScript({ googleMapsApiKey: mapsApiKey });
    const [markers, setMarkers] = useState([]);
    const [activeMarker, setActiveMarker] = useState(null);
    const mapRef = useRef(null);
    const firstLoad = useRef(true); // Track initial load

    const fetchMarkers = () => {
        axiosInstance.get('/drivers')
            .then(response => {
                console.log('Drivers fetched successfully:', response.data);
                setMarkers(response.data);

                if (firstLoad.current && response.data.length > 0 && mapRef.current) {
                    const bounds = new window.google.maps.LatLngBounds();
                    response.data.forEach((marker) =>
                        bounds.extend({ lat: marker.latitude, lng: marker.longitude })
                    );
                    mapRef.current.fitBounds(bounds);
                    firstLoad.current = false; // Disable fitBounds for subsequent updates
                }
            })
            .catch(error => {
                console.error('Error fetching markers:', error);
            });
    };

    useEffect(() => {
        if (!isLoaded) return;
        fetchMarkers(); // Initial fetch
        const interval = setInterval(fetchMarkers, constants.REACT_APP_REFRESH_INTERVAL); // Refresh every N seconds
        return () => clearInterval(interval);
    }, [isLoaded]);

    if (!isLoaded) return <div>Loading Map...</div>;

    return (
        <GoogleMap
            mapContainerStyle={googleMapStyle}
            center={center}
            zoom={10}
            options={{ styles: neutralMapStyle }}
            onLoad={(map) => (mapRef.current = map)}
        >
            {markers.map((driver, index) => (
                <MarkerF
                    key={index}
                    position={{ lat: driver.latitude, lng: driver.longitude }}
                    label={driver.car_number}
                    icon={driver.activity_status === 'active' ? redIcon : blueIcon}
                    onMouseOver={() => setActiveMarker(driver)}
                    onMouseOut={() => setActiveMarker(null)}
                >
                    {activeMarker && activeMarker.car_number === driver.car_number && (
                        <InfoWindow position={{ lat: driver.latitude, lng: driver.longitude }}>
                            <div className="info-window-content">
                                <h4>Profil vozača</h4>
                                <p><strong>Ime i prezime:</strong> {driver.name}</p>
                                <p><strong>Broj vozila:</strong> {driver.car_number}</p>
                                <p><strong>Broj telefona:</strong> {driver.phone_number}</p>
                                <p><strong>Vozilo:</strong> {driver.car_details}</p>
                                <p><strong>Status:</strong> {driver.activity_status === "active" ? constants.DRIVER_STATUS_ACTIVE : constants.DRIVER_STATUS_INACTIVE}</p>
                            </div>
                        </InfoWindow>
                    )}
                </MarkerF>
            ))}
        </GoogleMap>
    );
};

export default GoogleMapComponent;
