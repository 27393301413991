import React, { useContext } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { AuthContext } from "./authContext";

const AuthenticatedRoute = ({ children }) => {
    const { isAuthenticated } = useContext(AuthContext);
    const location = useLocation(); // Get current route

    console.log("🔍 Current path:", location.pathname);
    console.log("🔑 AuthenticatedRoute - isAuthenticated:", isAuthenticated);

    return isAuthenticated ? children : <Navigate to="/" replace />;
};

export default AuthenticatedRoute;
